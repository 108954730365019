
.detail-contain{
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 25px;
    max-width: 1340px;
}
.customMaxWidth {
    max-width: 1340px;
    width: 100%;
    margin: auto;
}
.detail{
    width: 100%;
    padding: 6% 5%;
    gap: 2rem;
    background-color: var(--blanco);
    min-height: 100vh;
}
#swiperDetail{
    display: flex;
    height: 34rem;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-width: 900px;
}
#swiperDetail img{
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: 100% 5%;
}
.imgDetail{
    display: flex;
    gap: 1rem;

}

.imgGrid{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.imgGrid img{
    width: 40vh;
    height: 28.7vh;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}
.img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}

.custom-modal {
    background: rgba(0, 0, 0, 0.8);
   
  }
  
  .custom-modal img {
    width: 100%;
    height: 100%;
 
  }
  
  iframe {
    width: 100%;
    height: 30vh;
    border-radius: 10px;
   
}
hr{
  
    border: 0.3px solid #00000013;
    
}

.share{
    background-color: var(--gris);
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    padding: 8px 9px;
    border-radius: 100%;
    cursor: pointer;
}
.favoritos-btn{
    background-color: var(--gris);
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    padding: 8px 9px;
    border-radius: 100%;
    cursor: pointer;
}

.deFlexDetail{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
    gap: 2rem;
}
.deFLexIcon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}
.title {
    font-size: 25px;
    color: var(--text-color2);
}
.detail-contain p{
    color: var(--text-color2);
    white-space: pre-line;
}
.detail-contain p::-webkit-scrollbar{
    width: 7px;
    height: 7px;
    cursor: pointer;
 }
 
 .detail-contain p::-webkit-scrollbar-thumb{
     background-color: var(--color1);
     width: 10px;
     border-radius:20px ;
     cursor: pointer;
 }

.deColumn{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.price {
    font-size: 25px;
    color: var(--color1);
    font-weight: 700;
    margin-top: 1rem;
  
}
.precioTachadoDetail {
    text-decoration: line-through;
    color: var(--text-color2)!important;
    font-size: 25px;
    font-weight: 700;
    margin-top: 1rem;
  }
.titlecoment{
    color: var(--text-color2);
  
}

.wpp{
   background-color: rgb(30, 184, 30);
   color: var(--blanco);
   
   padding: 10px 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 17px;
   cursor: pointer;
   box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
   border-radius: 10rem;
   gap: 10px;
   width: 10rem;
}
.wpp img{
    width: 25px;
}
.textDetail{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 24px;
    position: relative;
}
.back{
    background-color: var(--gris);
    border: none;
    padding: 7px 9px;
    border-radius: 100%;
    color: var(--text-color2);
    font-size: 17px;
   
}
.textDetail h4{
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--text-color2);
    font-weight: 500;
}
.deFLexBuet{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btnGoCheckout {
    background-color: var(--color1);
    padding: 11px;
    color: var(--blanco);
    border-radius: 60px;
    border: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 10px;
}

/*
CALENDAR
*/

.calendar-container {
    box-shadow: 0 0 5px rgba(0, 0, 0, .13);
    border-radius: 10px;
    width: 100%;
    max-width: 437px;
}
.calendar-title {
    background-color: var(--color1);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    margin-bottom: 16px;
}
.calendar-title h2 {
    color: var(--blanco);
    text-align: center;
    padding: 10px;
}
.calendar-box {
    padding-left: 15px;;
    padding-right: 15px;;
}
.calendar-box .deFlexGoTocart {
    justify-content: center;
}
.calendar-label {
    margin-bottom: 10px;;
}
.calendar-box .react-calendar {
    border: 2px solid rgba(0, 0, 0, .161);
    border-radius: 10px;
    width: 100%;
}
.calendar-box .buttons-container {
    margin-top: 16px;
}
.react-calendar__tile.available {
    background: var(--color3);
    color: var(--text-color);
}
.react-calendar__tile.available:hover,
.react-calendar__tile.selectedFront {
    background: var(--color1) !important;
    color: var(--blanco) !important;
    font-weight: bold;
}
.clientQty .deFlexCart {
    margin: 0;
}
.clientQty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    flex-wrap: wrap;
}
.clientQty h4 {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
}
.booking-fixed-container {
    box-shadow: 0 0 5px rgba(0, 0, 0, .13);
    display: inline-block;
    padding: 20px 30px;
    width: 100%;
    max-width: 407px;
    margin-top: 32px;
    position: fixed;
    top: 70px;
    text-align: center;
    justify-content: center;
}
.d-show {
    display: block;
}
.d-flex {
    display: flex;
}
.d-none {
    display: none;
}
/**/
.payment-methods-container {
    margin-top: 24px;
}
.payment-methods-container h4 {
    text-align: center;
}
.payment-methods-container img {
    max-width: 100%;
    width: 250px;
    margin: auto;
    display: block;
}
.booking-fixed {
    display: none;
}
.legend label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
.legend .legend-color {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: var(--color3);
    border-radius: 100%;
    margin-right: 8px;
}
@media (max-width: 1024px) {
    .deFLexIcon{
        gap: 1rem;
    }
    
    .detail-contain p{
        max-height: 100%;
        min-height:  100%;
    }
    .deFlexDetail{
        position: absolute ;
        z-index: 3;
        top: 15px;
        width: 100%;
        padding: 0 20px;
    }
    #swiperDetail{
        display: flex;
        width: 100%;
        height: 50vh;
        border-radius: 0;
        overflow: hidden;
      
    }
    #swiperDetail img{
      display: flex;
      width: 100%;
      border-radius: 0;
   
    }
   
    .detail{
       
        padding:   0;
    }
    .detail-contain{
        flex-direction: column;
        padding:  0 0 100px 0 ;
       
    }
    .textDetail{
        margin-top: -2.5rem;
        z-index: 1;
        border-radius: 1rem 1rem 0 0;
        background-color: var(--blanco);
        padding: 20px;
       
    }
    .calendar-container {
        margin: auto;
    }
}
@media (max-width: 768px) {
    .customFixedMobileMenu {
        position: fixed;
        background: rgba(255, 255, 255, .9);
        top: 0;
    }
    .customFixedMobileMenu .favoritos-btn,
    .customFixedMobileMenu .back,
    .customFixedMobileMenu .share {
        background-color: transparent;
        background: none;
    }
    .customFixedMobileMenu .cartIconFixed {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        color: gray;
        background: none;
        box-shadow: none;
        height: 40px;
        padding: 0;
    }
    .customFixedMobileMenu .cartIconFixed span {
        background-color: var(--text-color2);
        border-radius: 100%;
        font-size: 10px;
        position: absolute;
        margin-top: -18px;
        margin-left: 1.5rem;
        color: var(--blanco);
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .booking-fixed-container {
        display: none !important;
    }
    .booking-fixed {
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        width: 100%;
        bottom: 0;
        background-color: #fff;
        z-index: 10000;
        padding: 8px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .13);
        flex-wrap: wrap;
    }
    .booking-fixed .price {
        text-align: center;
        width: 350px;
        margin-bottom: 6px;
        margin-top: 0;
        font-size: 20px;
    }
    .booking-fixed .btnAdd {
        width: 350px;
        border-radius: 0;
        font-size: 18px;
        padding: 8px 15px;
    }
}