.dFlex {
    display: flex;
    justify-content: space-between;
    padding: 5px 25px;
    gap: 24px;
}
.dFlex > div {
    width: 100%;
}
.pl-1 {
    padding-left: 25px;
}