.CheckoutContain{
    padding: 0px 9%;
    min-height: 60vh;
    max-width: 1200px;
    margin: auto;
}
.checkoutForm {
    padding: 25px;
    margin-top: 32px;
    border: 1px solid var(--color1);
    border-radius: 10px;
    margin-bottom: 48px;;
}
.checkoutFormContainer {
    width: 100%;
    padding-top: 24px;
    border-top: 1px dotted var(--color1);
    margin-top: 24px;
}
.checkoutForm h4 {
    font-weight: 400;
}
.checkoutForm .formRow {
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
}
.checkoutFormContainer label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
}
.checkoutFormContainer .checkoutInput,
.checkoutFormContainer .checkoutSelect {
    border: 1px solid var(--color1);
    border-radius: 8px;
    padding: 8px 15px;
    background-color: var(--gris2);
    max-width: 100%;
    width: 100%;
}
.paymentMethdosContainer {
    margin-top: 24px;
}
.PaymentMethods {
    margin-bottom: 24px;;
}
.checkoutForm .formRow > div {
    width: 100%;
}
.goToPayBtn {
    border-color: rgb(42, 210, 201);
    box-sizing: border-box;
    background-color: #293c7a;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 1em;
    height: 46px;
    margin: 0;
    outline: none !important;
    padding: 12px 0;
    text-align: center;
    width: 232px;
}
.goToPayBtn.disabled {
    opacity: .7;
}
.w-100 {
    width: 100%;
}
.d-block {
    display: block !important;
}
.mt-2 {
    margin-top: 16px;
}
.mt-3 {
    margin-top: 24px;
}
.mt-4 {
    margin-top: 32px;
}
.successTable {
    width: 100%;
    padding: 10px 15px;
    margin-top: 16px;
    border-collapse: collapse;
}
.successTable tr:nth-child(even){
    background-color: #f2f2f2;
}
.successTable tr:hover {
    background-color: #ddd;
}
.successTable td {
    border: 1px solid #ddd;
    padding: 8px;
}
.cartContainer table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 16px;
}
.cartContainer table th {
    font-size: 15px;
}
.cartContainer table th,
.cartContainer table td {
    padding: 5px 15px;
    text-align: left;
}
.checkoutCard img {
    width: 150px;
    border-radius: 10px;
}
.text-center {
    text-align: center !important;
}
.subtitleContainer {
    text-align: center;
    margin-top: 32px;
}
.customCountrySelect > div {
    border: 1px solid var(--color1);
    border-radius: 8px;
    background-color: var(--gris2);
    max-width: 100%;
    width: 100%;
    min-height: 33px;
}
.customCountrySelect .css-1xc3v61-indicatorContainer {
    padding: 6px;
}
.overflow-auto {
    overflow: auto !important;
}
@media (max-width: 480px){
    .checkoutForm .formRow {
        display: block;
    }
    .checkoutForm .formRow > div {
        margin-bottom: 16px;
    }
    .goToPayBtn, .kr-popin-button {
        width: 479px !important;
        max-width: 100% !important;
    }
}